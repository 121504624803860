<template>
  <!-- 添加绩效申诉 -->
  <div class="jxAddPerformance">
    <van-nav-bar title="添加绩效申诉"
                 left-text=""
                 left-arrow
                 @click-left="onClickLeft" />
    <div class="overflow_div">
      <van-form @submit="onSubmit">
        <van-field v-model="jobNum"
                   center
                   name="jobNum"
                   label="工号"
                   input-align="right"
                   readonly />
        <van-field v-model="name"
                   name="name"
                   label="目标主题"
                   input-align="right"
                   readonly />
        <van-field readonly
                   clickable
                   :value="assessmentCycle"
                   label="考核周期"
                   input-align="right"
                   name="assessmentCycle"
                   placeholder="请选择"
                   right-icon="arrow"
                   @click="showTime = true" />
        <van-popup v-model="showTime"
                   round
                   position="bottom">
          <van-datetime-picker v-model="currentDate"
                               type="date"
                               title="选择报名日期"
                               :min-date="minDate"
                               @confirm="onConfirmTime"
                               @cancel="showTime = false" />
        </van-popup>
        <van-field class="bring_score"
                   v-model="score"
                   name="score"
                   label="考核分数"
                   input-align="right"
                   readonly />
        <van-field v-model="level"
                   name="level"
                   label="考核等级"
                   input-align="right"
                   readonly />
        <van-field readonly
                   clickable
                   label="期望等级"
                   :value="expectationLevel"
                   name="expectationLevel"
                   input-align="right"
                   placeholder="请选择"
                   right-icon="arrow"
                   @click="showLevel = true" />
        <van-popup v-model="showLevel"
                   round
                   position="bottom">
          <van-picker title="期望等级"
                      show-toolbar
                      :columns="columns"
                      @confirm="onConfirmLevel"
                      @cancel="onCancelLevel" />
        </van-popup>
        <van-field v-model="appealReasons"
                   name="appealReasons"
                   label="申诉原因"
                   rows="5"
                   autosize
                   type="textarea"
                   maxlength="150"
                   placeholder="请输入...(最多可输入150字)"
                   show-word-limit />
        <van-field v-model="remarks"
                   name="remarks"
                   label="备注"
                   rows="5"
                   autosize
                   type="textarea"
                   maxlength="150"
                   placeholder="请输入...(最多可输入150字)"
                   show-word-limit />
        <div class="offerFooter">
          <van-button type="info"
                      native-type="submit">保存</van-button>
          <!-- <van-button type="info"
                @click="saveForm">保存</van-button> -->
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
export default {
  data () {
    return {
      jobNum: 'H00001',
      name: '张三',
      assessmentCycle: '',
      showTime: false,
      minDate: new Date(),
      currentDate: new Date(),
      score: 90,
      level: 'B',
      expectationLevel: '',
      showLevel: false,
      columns: ["A", "B", "C", "D", "E", "F", "G", "H"],
      appealReasons: '',
      remarks: ''
    }
  },
  methods: {
    // 回退
    onClickLeft () {
      this.$router.push('/jxPerformanceAppeals')
      // if (this.$route.query.fromType == '主页')
      // {
      //   this.$router.push('/')
      // } else if (this.$route.query.fromType == '报名成功')
      // {
      //   this.$router.push('/signUpSuccessfully')
      // }
    },
    onConfirmTime (value) {
      let dateTime = ''
      if (value)
      {
        let y = value.getFullYear()
        let m = value.getMonth() + 1
        m = m < 10 ? ('0' + m) : m
        let d = value.getDate()
        d = d < 10 ? ('0' + d) : d
        // let h = value.getHours()
        // h = h < 10 ? ('0' + h) : h
        // let M = value.getMinutes()
        // M = M < 10 ? ('0' + M) : M
        //  let s =date.getSeconds()
        //  s = s < 10 ? ('0' + s) : s
        // dateTime = y + '-' + m + '-' + d + ' ' + h + ':' + M;
        dateTime = y + '-' + m + '-' + d
      }
      this.assessmentCycle = dateTime
      this.showTime = false
    },
    onConfirmLevel (value, index) {
      this.showLevel = false
      this.expectationLevel = value
    },
    onCancelLevel () {
      this.showLevel = false
    },
    onSubmit (values) {
      console.log('666666666666666', values)
    },
  }
}
</script>

<style lang="less" scoped>
.jxAddPerformance {
  height: 100vh;
  overflow: hidden;
  background: #eceaea;
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .overflow_div {
    overflow: auto;
    height: calc(100% - 210px);
    // background: #fff;
    /deep/.van-form {
      .van-field {
        margin-bottom: 20px;
        .van-field__label {
          span {
            font-size: 32px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            line-height: 54px;
            color: #000000;
          }
        }
        .van-field__value {
          .van-field__body {
            input,
            textarea {
              font-size: 32px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              line-height: 54px;
              color: #333333;
            }
          }
        }
      }
      .bring_score {
        .van-field__value {
          .van-field__body {
            input {
              color: #2b8df0;
            }
          }
        }
      }
    }
  }
  .offerFooter {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 120px;
    padding: 20px;
    box-sizing: border-box;
    background: #fff;
    justify-content: center;
    .van-button {
      width: 80%;
      border-radius: 10px;
      .van-button__text {
        font-size: 28px;
        font-family: Source Han Sans CN;
      }
    }
  }
}
</style>